.pageshell {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.mask {
  z-index: 26;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
}

.trip_enter {
  animation-name: enters1; // 动画名称
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-delay: 0s; // 动画延迟时间
  animation-iteration-count: 1;
  animation-duration: 0.1s;

  @keyframes enters1 {
    from {
      top: 100vh;
      opacity: 0;
    }

    to {
      top: 0;
      opacity: 1;
    }
  }
}

.page {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: 27;

  .content {
    position: absolute;
    display: block;
    width: 100vw;
    left: 0px;
    top: unset;
    bottom: 0;
    background-color: transparent;
    background-color: rgba(252, 255, 255, 0);
    background-image: unset;

    .header {
      .toolbar {
        display: flex;
        height: 5vh;
        align-items: center;
        padding: 0 10px 0 10px;
        flex-direction: row;
        background-color: transparent;
        //background-color: rgba(252,255,255,0);
        justify-content: space-between;

        img {
          opacity: 1;
          height: 24px;
        }
      }

      .title {
        width: 100vw;
        // height: 5vh;
        opacity: 1;
        // padding-left: 20px;
        border-radius: 15px 15px 0px 0px;
        background: rgba(255, 255, 255, 1);
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          img {
            height: 18px;
          }

          margin-right: 5px;
          font-size: 18px;
          line-height: 0.6em;
          font-weight: 800;
        }
      }
    }
  }

  .enter {
    position: relative;
    bottom: 0;
    animation-name: enters; // 动画名称
    animation-direction: alternate;
    animation-timing-function: linear;
    animation-delay: 0s; // 动画延迟时间
    animation-iteration-count: 1;
    animation-duration: 0.15s;

    @keyframes enters {
      from {
        left: 100vw;
      }
      to {
        left: 0;
      }
    }
  }

  .box2 {
    position: relative;
    width: 100vw;
    height: 74vh;
    opacity: 1;
    background: rgba(41, 166, 102, 1);
    overflow-y: auto;

    .page2 {
      .big_title {
        display: flex;
        padding-top: 30px;
        justify-content: center;
        height: 18.7vw;

        img {
          margin: 0, 0;
        }
      }

      .row2 {
        color: white;
        height: 10.3vw;
        font-size: 18px;
        font-weight: 400;
      }

      .card {
        width: 85vw;
        // height: 94.4vw;
        border-radius: 13px;
        background: white;
        margin: 0 auto;
        box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.08), -5px -5px 10px 5px rgba(0, 0, 0, 0.08);

        .update {
          height: 20vw;
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          padding-top: 25px;

          &>:first-child {
            font-size: 18.5px;
            height: 26px;
          }

          &>:last-child {
            font-size: 15.5px;
            font-weight: 400;
            color: rgba(161, 161, 161, 1);
            text-align: center;
            height: 26px;
          }
        }

        .arrow {
          height: 50vw;
          width: 100%;
          padding-top: 20px;

          img {
            width: 38vw;
            animation-name: likes; // 动画名称
            animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
            animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
            animation-delay: 0s; // 动画延迟时间
            animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
            animation-duration: 1.5s; // 动画完成时间
          }

          @keyframes likes {
            0% {
              transform: scale(1.2);
            }

            50% {
              transform: scale(1);
            }

            100% {
              transform: scale(1.2);
            }
          }
        }

        &>:last-child {
          width: 100%;
          padding: 2.5px 20px 15px 20px;
          text-align: left;
          font-size: 16.2px;
          box-sizing: border-box;

          &>:last-child {
            &>:first-child {
              font-weight: 400;
              color: rgba(148, 148, 148, 1);
            }

            &>:last-child {
              font-weight: 800;
            }
          }
        }

      }

      .notes {
        width: 85vw;
        color: rgba(229, 229, 229, 1);
        text-align: center;
        padding: 10px 10px;
        margin: 0 auto;

        p {
          font-size: 11px;
          font-weight: 400;
        }

        .server {
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          padding: 5px 0;

          hr {
            width: 26%;
            margin: auto 0;
          }
        }

        .server-icon {
          display: flex;
          justify-content: space-between;
          flex-direction: row;

          img {
            height: 20px;
          }
        }

        .server-phone {
          margin-top: 20px;
          margin-bottom: 30px;
        }

        .sever-support {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 14px;
          }

          span {
            font-size: 12px;
            margin-left: 4px;
          }
        }
      }
    }
  }

  .fooder {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 9vh;
    opacity: 1;
    background: rgba(255, 255, 255, 1);

    &>:first-child {
      height: 33.15px;
      margin: 0 auto;
    }

    &>:last-child {
      height: 57px;
      margin: 0 auto;
    }
  }

  .box1 {
    @fillclr: #004392;
    @inputclr: #C9C9C9;

    position: relative;
    width: 100vw;
    height: 83vh;
    opacity: 1;
    background: white;
    overflow-y: auto;

    .page1 {

      .BigTitlePage1 {
        display: flex;
        padding-top: 15px;
        justify-content: center;
        height: 6vh;
        margin-top: 15px;

        img {
          margin: 0, 0;
        }
      }


      .row2 {
        color: @fillclr;
        height: 10.3vw;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 15px;
      }

      .card {
        width: 85vw;
        border-radius: 13px;
        background: white;
        margin: 0 auto;
        box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.08), -5px -5px 10px 5px rgba(0, 0, 0, 0.08);
        padding: 30px 0 20px 0;

        .row {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          align-items: center;
          color: #C9C9C9;
          padding: 10px 30px;
          font-size: 18px;
          margin: 1em 0;

          &>* {
            padding: 0 5px;
          }

          span {
            line-height: 1em;
          }

          .col2 {
            flex-grow: 1;
            text-align: left;
          }

          .row3 {
            line-height: 1.5em;
          }

          .row4 {
            margin-top: 0;
            font-size: 14px;
            color: rgb(55, 115, 227);
            text-decoration: underline;
            width: 100%;
            text-align: right;
            margin-top: 0px;
          }

          button {
            width: 100%;
            height: 56px;
            border-radius: 9px;
            background-color: rgba(168, 200, 251, 1);
            border: none;
            color: white;
          }
        }
      }

      .notes {
        width: 85vw;
        color: rgba(229, 229, 229, 1);
        text-align: center;
        padding: 10px 10px;
        margin: 0 auto;

        p {
          font-size: 11px;
          font-weight: 400;
        }

        .server {
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          padding: 5px 0;
          margin-bottom: 10px;

          hr {
            width: 26%;
            margin: auto 0;
          }
        }

        .server-icon {
          display: flex;
          justify-content: space-between;
          flex-direction: row;

          img {
            height: 15px;
          }
        }

        .server-phone {
          margin-top: 15px;
          margin-bottom: 15px;

          span {
            color: rgb(55, 115, 227);
          }
        }


      }

      .sever-support {
        color: white;
        display: flex;
        margin: 0 auto;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        box-sizing: border-box;
        width: 85%;
        height: 14.25vw;
        border-radius: 11px;
        background: rgba(99, 134, 236, 1);
        font-weight: 400;

        div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          img {
            height: 16px;
            margin: 5px auto;
          }

          span {
            font-size: 12px;
            margin-left: 4px;
            margin-top: 5px;
          }
        }
      }

      .sx {
        width: 1px;
        height: 80%;
        background-color: rgb(249, 248, 248);
        opacity: 0.3;
        flex-grow: -1;
      }

      .left {
        width: 74%;
      }

      .right {
        width: 24.6%;
      }
    }
  }
}
@primary-color: #1DA57A;