@import 'antd/dist/antd.less';
.App {
  //position: absolute;
  text-align: center;
  font-family: "Helvetica", "PingFang SC", sans-serif;
  background-color: #fff8f8;
  width: 100vw;
  height: 100vh;

  .blur {
    background-attachment: fixed;
    z-index: 15;
  }


  .menu-item {
    display: flex;
    align-items: center;
    width: 50%;

    span {
      font-size: 14px;
      padding-left: 5px;
    }
  }
}

.nav {
  position: fixed;
  display: flex;
  box-sizing: border-box;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  height: 11vw;
  width: 100vw;
  padding: 4vw;
  padding-right: 0vw;
  background-color: white;

  img {
    height: 4.5vw;
    flex-grow: 0;
  }

  span {
    flex-grow: 1;
    text-align: left;
    font-size: 4.5vw;
    font-weight: 800;
    padding-left: 4vw;
    font-family: 'PingFangSC-Thin', sans-serif;
  }

  z-index: 10;
}

.content {
  width: calc(100vw + 0.5px);
  position: relative;
  top: 11vw;
  display: flex;
  left: -0.5px;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  z-index: 5;
  background-color: #F8F8FF;
  background-image: url('./static/map.svg');
  background-size: calc(100vw + 0.5px);
  background-repeat: no-repeat;

  .map {
    width: 100%;
    margin-top: 3.5vw;
    margin-bottom: 3.5vw;

    .map-table {
      .name {
        width: 20vw;
      }

      .title {
        display: flex;
        font-size: 3.8vw;
        font-weight: 200;
        color: #DDD;
        margin-bottom: 1vw;
      }

      .details {
        display: flex;
        font-size: 4.8vw;
        color: #FFF;
        font-weight: 400;
      }
    }

  }

  .main {
    width: 88vw;
    margin: 0 auto;

    .code {
      padding-bottom: 4vw;
      .time {
        font-size: 5.6vw;
        font-weight: 400;
        color: #272727;
        margin: 1vw;
      }

      .sec {
        font-size: 6vw;
        color: #3196FB;
        padding-right: 1vw;
        font-weight: 700;
      }
    }

    .sampling {
      color: #3AB259;

      &>:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        line-height: 2em;

        &>:first-child {
          font-size: 18px;
          margin-right: 5.5px;
        }

        &>:last-child {
          font-size: 22px;
          font-weight: 800;
        }
      }

      &>:last-child {
        font-size: 20px;
        font-weight: 200;
      }
    }

    .checktrip {
      position: relative;
      display: none;//block;
      height: 30px;

      img {
        width: 25vw;
        top: -10px;
        left: unset;
        position: absolute;
        right: -6vw;
      }

      hr {
        position: relative;
        top: 20px;
      }
    }

    .trip-card {
      display: none;//flex;
      justify-content: space-between;
      flex-grow: 0 1 0;
      padding: 1vw 4vw 2vw 4vw;
      align-items: center;

      .show-card {
        padding-left: 2.5vw;
        flex-grow: 1;
        width: 100%;
        text-align: left;
        font-weight: 400;
        color: #474747;
        letter-spacing: 1px;
        font-size: 4vw;
      }
    }

    .md-card {
      display: flex;
      justify-content: space-between;

      img {
        width: 40vw;
      }

      .checkTime {
        color: white;
        font-size: 3vw;
        position: absolute;
        width: 100%;
        top: 20vw;
        font-weight: 200;
      }
    }

    .bottom-card {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: flex-start;
      width: 88vw;
      padding: 2vw;
      padding-top: 4vw;

      .item {
        width: 33.33%;
        height: 22vw;
        //margin: 2vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 12vw;
        }

        div {
          color: #474747;
          font-size: 3.5vw;
          padding: 1.5vw 0;
          letter-spacing: 0.5px;
        }
      }
    }
  }

  .foord {
    .tele {
      text-align: bottom;

      .contan {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-size: 4vw;
        color: #979797;
        font-weight: 700;

        //margin: 2vw;
        img {
          margin-right: 2vw;
        }
      }

      div {
        margin: 1vw;
        margin-bottom: 9vw;
        font-size: 2.5vw;
        color: #979797;
        font-weight: 400;
      }
    }
  }
}

.card {
  position: relative;
  background-color: white;
  border-radius: 1.5vw;
  margin-bottom: 3vw;
  box-shadow: 5px 5px 10px rgba(136, 136, 136, 0.05);

  hr {
    border: none;
    height: 1px;
    background-color: #e5e5e5;
  }
}
@primary-color: #1DA57A;