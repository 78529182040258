.container{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
}

.mask {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: black;

}

.page {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0;
}

.phone {
    font-weight: 400;
    font-size: 20px;
    color: #3A3A3A;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    background: white;
    width: 75vw;
    border-radius: 8px;
    padding: 20px 18px;
    box-sizing: border-box;
    opacity: 1;
}

.row1 {
    margin-top: 10px;
    font-size: 14px;
    color: #939297;
    height: 25px;
    line-height: 25px;
}

.row3 {
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: start;
    margin:10px 0;
    padding-top: 15px;
}

.checkBox {
    height: 16px;
    margin: 0 10px;
}

.sm {
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 1.6em;
    color: #939297;
    margin-left: 5px;
    margin-top: -3px;
    text-align: left;
}

.button {
    background: #3478F3;
    border-radius: 16px;
    height: 47px;
    color: white;
    font-size: 20px;
    display: flex;
    align-items:center;
    justify-content: center;
    letter-spacing:0.5em;
    margin:20px;
}

.close{
    height: 45px;
    display:flex;
    justify-content:flex-end;
    align-items:flex-end;
}
@primary-color: #1DA57A;